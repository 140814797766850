import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const CoverPaper = styled(Paper)`
  border-radius: 0px;
  min-height: calc(100vh - 100px);
  padding-bottom: 30px;
  background: rgb(0, 36, 15);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 15, 0.9807171242774566) 0%,
    rgba(0, 0, 0, 0.9749367774566474) 50%,
    rgba(31, 33, 33, 1) 100%
  );
  position: relative;
`;

export default CoverPaper;
