import { configureStore } from '@reduxjs/toolkit';
import baseApi from '../api/baseApi';
import gameReducer from './slices/gameSlice';
import userReducer from './slices/userSlice';
import modalReducer from './slices/modalSlice';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    game: gameReducer,
    user: userReducer,
    modal: modalReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch