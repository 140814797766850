import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { updateTradeOffer } from "../../../store/slices/userSlice";
import {
  useUpdateTradeUrlMutation,
} from "../../../api/profileApi";
import { RootState } from "../../../store/store";
import { Box, Button, TextField, Typography } from "@mui/material";
import InvertedButton from "../../../components/InvertedButton";
import { toast } from "react-toastify";

const Profile: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user.user);
  const [tradeUrl, setTradeUrl] = useState<string>(user?.tradeOfferUrl || "");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && tradeUrl === "") {
      setTradeUrl(user.tradeOfferUrl);
    }
  }, [user]);

  const updateHandler = async () => {
    try {
      const res = await updateTradeUrl({ tradeOfferUrl: tradeUrl }).unwrap();
      dispatch(updateTradeOffer(res.tradeOfferUrl));
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  const [updateTradeUrl] = useUpdateTradeUrlMutation();
  return (
    <>
      {user ? (
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography>User: {user.name}</Typography>
          <Box display="flex" gap={2}>
            <Typography>Balance: {user.balance}</Typography>
          </Box>
          <Box display="flex" gap={2} alignItems="flex-start">
            <TextField
              onChange={(e) => setTradeUrl(e.target.value)}
              value={tradeUrl}
              helperText={"paste your full trade link here"}
              label={"trade link"}
              color="success"
              sx={{
                input: {
                  color: "white",
                  background: "#acadac",
                },
              }}
            />
            <InvertedButton
              actColor="white"
              bgColor="success.main"
              sx={{ width: "200px" }}
              onClick={updateHandler}
            >
              save
            </InvertedButton>
          </Box>
        </Box>
      ) : (
        <div>not logged in</div>
      )}
    </>
  );
};

export default Profile;
