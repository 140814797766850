import React, { useState, useCallback } from "react";
import { useGetUserInventoryQuery } from "../../../api/profileApi";
import Box from "@mui/material/Box";
import Card from "./Card";
import LowOverlay from "./LowOverlay";
import AcceptTradeModal from "../../../components/AcceptTradeModal";
import { InventoryItem } from "../../../types/inventoryTypes";
import CoverPaper from "../../../components/CoverPaper";
import Loading from "../../../components/Loading";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

const Inventory: React.FC = () => {
  const { data: userInventory, isLoading } = useGetUserInventoryQuery();
  const [checkData, setCheckData] = useState<Array<InventoryItem>>([]);
  const onChangeCallBack = useCallback(
    (checked: boolean, asset: InventoryItem) => () => {
      if (checked) {
        setCheckData((prev) =>
          prev.filter((check) => check.assetId !== asset.assetId)
        );
      } else {
        setCheckData((prev) => [...prev, asset]);
      }
    },
    []
  );
  return (
    <CoverPaper>
      <Container maxWidth="xl">
        <Alert
          severity="warning"
          sx={{
            mb: 2,
          }}
        >
          To receive credits in your balance, you need to deposit skins from
          your inventory. To do so, kindly choose the skin you want to exchange
          for credits, push the "deposit" button, and send the trade offer to
          one of our bots.When sending a trade, please ensure that you only send
          items with fixed prices listed below in your inventory. Our bots
          accept all incoming trade offers, including items that have not been
          priced, every 5 minutes. If your balance remains the same after 5
          minutes from the deposit, kindly refresh the page. If you accidentally
          send the wrong skin, which you did not choose, please contact us on
          the Discord server or via email. In case your items do not have
          correct prices or are not priced at all, make sure to contact the
          support team on Discord to get the best price for your skin. Please
          pay attention that due to the instability of prices, skins over 100 C
          require manual approval, and it may take a few hours for them to be
          priced after you reach out to support.
        </Alert>
        {isLoading || !userInventory ? (
          <Loading />
        ) : (
          <Box
            display="flex"
            gap={{ xs: 3, md: 20 }}
            flexWrap="wrap"
            justifyContent="center"
          >
            {Object.values(userInventory.inventory).map((item) => (
              <Card
                item={item}
                key={item.assetId}
                checked={checkData.includes(item)}
                setChecked={onChangeCallBack}
              />
            ))}
          </Box>
        )}
        {checkData.length > 0 && <LowOverlay items={checkData} />}
      </Container>
      <AcceptTradeModal />
    </CoverPaper>
  );
};

export default Inventory;
