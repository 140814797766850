import baseApi from "./baseApi";
import { IWithdrawDetails, IWithdrawHistory, Iprofile, ItradeHistory } from "../types/ProfileTypes";
import { InventoryType } from "../types/inventoryTypes";
import { IInventory } from "../types/withdrawTypes";

interface AuthReturnType {
    authenticated: boolean;
    user: Iprofile
}

export const profileApi = baseApi.enhanceEndpoints({addTagTypes: ['profile', 'trades', 'withdraw', 'withdrawHistory']}).injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.mutation<AuthReturnType, void>({
            query: () => 'user/',
        }),
        logout: builder.mutation<string, void>({
            query: () => ({
                url:'user/logout/',
            method: "POST"
        })
        }),
        getUserInventory: builder.query<{inventory: InventoryType}, void>({
            query: () => 'user/steamInventory/',
            keepUnusedDataFor: 120
        }),
        updateTradeUrl: builder.mutation<{tradeOfferUrl: string}, {tradeOfferUrl: string}>({
            query: ({tradeOfferUrl}) => ({
                url: '/user/tradeUrl/',
                method: 'POST',
                body: {tradeOfferUrl},
            })
        }),
        getTradeHistory: builder.query<ItradeHistory, void>({
            query: () => 'user/tradeHistory/',
            providesTags: ['trades'],
            keepUnusedDataFor: 120
        }),
        getWithdrawHistory: builder.query<IWithdrawHistory, void>({
            query: () => 'user/withdrawHistory/',
            keepUnusedDataFor: 120,
            providesTags: ['withdrawHistory']
        }),
        getWithdrawDetails: builder.query<IWithdrawDetails, string>({
            query: (id) => ({
                url: `user/withdrawDetails/${id}`,
            }),
            keepUnusedDataFor: 360,
            providesTags: (result, error, arg) => [{type: 'withdrawHistory', id: arg}]
        }),
        getStock: builder.query<IInventory[],void>({
            query: () => 'skins/stock/',
            providesTags: ['withdraw'],
            keepUnusedDataFor: 60,
        }),
        withdrawOffer: builder.mutation<{new_balance: string}, string[]>({
            query: (ids) => ({
                url: 'skins/withdrawrequest/',
                method: 'POST',
                body: {ids}
            }),
            invalidatesTags: ['withdraw', 'withdrawHistory']
        }),
    }
    )
})

export const { 
    useGetUserMutation,
    useLogoutMutation,
    useGetUserInventoryQuery,
    useUpdateTradeUrlMutation, 
    useGetTradeHistoryQuery,
    useGetStockQuery,
    useWithdrawOfferMutation,
    useGetWithdrawHistoryQuery,
    useLazyGetWithdrawDetailsQuery
 } = profileApi;
