import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { Link } from "react-router-dom";

import "./index.css";
import Chip from "@mui/material/Chip";
const pages = ["Tables", "FAQ", "Marketplace"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const user = useAppSelector((state: RootState) => state.user.user);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component={Link} to={"/"}>
            <Box
              component="img"
              src="/assets/mainLogo.png"
              alt="main logo"
              width={140}
              display={{ xs: "none", md: "block" }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu icon"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link to={page.toLowerCase()}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box component={Link} to={"/"} flexGrow={{ xs: 1, md: 0 }}>
            <Box
              component="img"
              src="/assets/mainLogo.png"
              alt="main logo"
              width={140}
              display={{ xs: "block", md: "none" }}
            />
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, ml: 10 }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textAlign: "center",
                  textTransform: "inherit",
                }}
                component={Link}
                to={page.toLowerCase()}
              >
                <Typography fontFamily="Segoe UI" fontWeight={700}>
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }} display="flex" alignItems="center">
            {user && (
              <Chip
                size={"medium"}
                label={user.balance}
                sx={{ bgcolor: "#fff", mr: "4px" }}
                avatar={<Avatar alt="coin" src="/assets/chips/500.webp" />}
              />
            )}
            <Tooltip title={user ? "My account" : "Sign in with steam"}>
              {user ? (
                <IconButton
                  component={Link}
                  sx={{ p: 0 }}
                  to="/accounts/profile"
                >
                  <Avatar alt={"user avatar"} src={user.avatarUrl} />
                </IconButton>
              ) : (
                <IconButton href="/api/accounts/steam/login">
                  <Avatar
                    alt={"steam logo"}
                    src="/assets/square-steam.svg"
                    sx={{ filter: "invert(1)" }}
                  />
                </IconButton>
              )}
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
