import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ActionEnum } from "../../Enums/actionEnum";
import { useDecisionMutation } from "../../api/tableApi";

interface props {
  tableId: string;
  type: "action" | "choice" | undefined;
  toCall: number | undefined;
  bigBlind: number;
  balance: number;
  stake: number | undefined;
}
const ActionBar: React.FC<props> = ({
  tableId,
  type,
  toCall,
  bigBlind,
  balance,
  stake,
}) => {
  const [makeDecision] = useDecisionMutation();

  const clickHandler = (actionEnum: ActionEnum, value?: number) => () => {
    const raiseValue = value ? value - (stake || 0) - (toCall || 0) : undefined;
    makeDecision({ action: actionEnum, tableId, value: raiseValue });
  };
  const toCallMin = toCall ? toCall * 2 : 0;
  const minRaiseValue = Math.max(toCallMin, bigBlind * 2);
  const [raiseValue, setRaiseValue] = useState<number | number[]>(
    minRaiseValue
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        width: "700px",
        alignItems: "center",
        float: "right",
        flexWrap: "wrap",
        mt: "60px",
      }}
    >
      {type === "action" && (
        <>
          <Button
            onClick={clickHandler(ActionEnum.call)}
            variant="contained"
            color="success"
            sx={{ width: "200px" }}
          >
            {toCall ? "Call" : "Check"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={clickHandler(ActionEnum.fold)}
            sx={{ width: "200px" }}
          >
            Fold
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ width: "200px" }}
            onClick={clickHandler(ActionEnum.allIn)}
          >
            All In
          </Button>
          <Slider
            value={raiseValue}
            step={toCall ? toCall : bigBlind}
            marks
            min={minRaiseValue}
            max={balance < minRaiseValue ? minRaiseValue : Math.floor(balance)}
            valueLabelDisplay="auto"
            onChange={(e, value) => setRaiseValue(value)}
            disabled={minRaiseValue > balance}
            sx={{
              width: "420px",
            }}
            color="success"
          />
          <Button
            variant="contained"
            color="success"
            onClick={clickHandler(
              ActionEnum.raise,
              Array.isArray(raiseValue) ? raiseValue[0] : raiseValue
            )}
            disabled={minRaiseValue > balance}
            sx={{ width: "200px" }}
          >
            Raise
          </Button>
        </>
      )}
      {type === "choice" && (
        <>
          <Button
            variant="contained"
            color="success"
            onClick={clickHandler(ActionEnum.show)}
            sx={{ width: "200px" }}
          >
            Show
          </Button>
          <Button
            variant="contained"
            onClick={clickHandler(ActionEnum.muck)}
            color="error"
            sx={{ width: "200px" }}
          >
            Muck
          </Button>
        </>
      )}
    </Box>
  );
};

export default ActionBar;
