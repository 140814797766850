export const cardEnumMap = new Map([
    [0, "two"],
    [1, "three"],
    [2, "four"],
    [3, "five"],
    [4, "six"],
    [5, "seven"],
    [6, "eight"],
    [7, "nine"],
    [8, "ten"],
    [9, "jack"],
    [10, "queen"],
    [11, "king"],
    [12, "ace"]
]);

export const suitEnumMap = new Map([
    [0, "ofspades"],
    [1, "ofclubs"],
    [2, "ofdiamonds"],
    [3, "ofhearts"]
]);