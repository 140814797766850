import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <Paper sx={{ borderRadius: 0 }}>
      <Container maxWidth="xl">
        <Box
          minHeight={80}
          display="flex"
          flexDirection={{ md: "row", xs: "column" }}
          sx={{
            ".MuiButton-text": {
              fontFamily: "Butch",
            },
          }}
        >
          <Box
            display="flex"
            pt={2}
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexWrap={"wrap"}
          >
            <Button
              sx={{ fontSize: 24, color: "#fff" }}
              href="/cs2acestos.pdf"
              target="_blank"
            >
              Terms of use
            </Button>
            <Button
              sx={{ fontSize: 24, color: "#fff" }}
              href="/cs2acespolicy.pdf"
              target="_blank"
            >
              Privacy Policy
            </Button>
            <Button
              component={Link}
              to="/fairness"
              sx={{ fontSize: 24, color: "#fff" }}
            >
              Fairness
            </Button>
            <Button
              sx={{ fontSize: 24, color: "#fff" }}
              href="https://discord.gg/N6u6ZRnHxR"
              target="_blank"
            >
              Contact Us
            </Button>
          </Box>
          <Box
            display="flex"
            width={{ md: "50%" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            gap={2}
            alignItems="center"
            flexWrap={{ xs: "wrap", md: "nowrap" }}
          >
            <a
              href="https://discord.gg/N6u6ZRnHxR"
              target="_blank"
              rel="noreferrer"
            >
              <Box component={"img"} src="/assets/discord.png" width={80} />
            </a>
            <a
              href="https://twitter.com/CS2ACES"
              target="_blank"
              rel="noreferrer"
            >
              <Box component={"img"} src="/assets/xLogo.png" width={80} />
            </a>
            <Box
              component={"img"}
              src="/assets/steamNon.png"
              width={186}
              ml={1}
            />
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default Footer;
