import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { InventoryItem } from "../../../types/inventoryTypes";
import { memo } from "react";

interface Iprops {
  item: InventoryItem;
  checked: boolean;
  setChecked: (checked: boolean, asset: InventoryItem) => () => void;
}

const Card: React.FC<Iprops> = ({ item, checked, setChecked }) => {
  const modifiedName = (name: string) => {
    let moddedName = name.replace("StatTrak", "ST");
    moddedName = moddedName.replace("(Battle-Scarred)", "(BS)");
    moddedName = moddedName.replace("(Well-Worn)", "(WW)");
    moddedName = moddedName.replace("(Field-Tested)", "(FT)");
    moddedName = moddedName.replace("(Minimal Wear)", "(MW)");
    moddedName = moddedName.replace("(Factory New)", "(FN)");

    return moddedName;
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      sx={{
        width: {
          xs: "40%",
          md: "18%",
          lg: "13%",
          userSelect: "none",
          MozUserFocus: "none",
          WebkitUserSelect: "none",
        },
        border: "1px solid",
        borderColor: checked ? "#90EE90" : "black",
        borderRadius: "20px",
        gap: "3px",
        cursor: item.not_accepted ? "not-allowed" : "pointer",
      }}
      textAlign="center"
      onClick={setChecked(checked, item)}
    >
      <Checkbox sx={{ position: "absolute", left: 0 }} checked={checked} />
      <Box
        component="img"
        src={`https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`}
        sx={{
          width: {
            xs: "50px",
            sm: "100px",
            md: "150px",
          },
        }}
        height="100%"
      />
      <Typography>{modifiedName(item.market_hash_name)}</Typography>
      <Box>
        <Typography>
          {item.not_accepted
            ? "Currently not accepted"
            : `${item.price || 0} coins`}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(Card);
