import React, { forwardRef } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { SxProps, Theme } from "@mui/system";

interface ButtonPropsOverride {
  children: React.ReactNode;
  bgColor: string;
  actColor: string;
  sx?: SxProps<Theme> | undefined;
}

const InvertedButton = forwardRef<
  HTMLButtonElement,
  ButtonPropsOverride & ButtonProps
>(({ children, bgColor, actColor, sx, ...props }, ref) => (
  <Button
    sx={{
      color: actColor,
      backgroundColor: bgColor,
      borderRadius: "10px",
      "&:hover": {
        color: bgColor,
        backgroundColor: actColor,
      },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Button>
));

export default InvertedButton;
