import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import Footer from "../../components/Footer";

const Root: React.FC = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Root;
