import React, { useEffect, useState } from "react";
import ModalContent from "../ModalContent";
import Slider from "@mui/material/Slider";
import { Modal, Box, Button } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/store";
import { hideActiveOverlay } from "../../store/slices/modalSlice";
import { useJoinMutation } from "../../api/tableApi";
import { remove_low_balance_flag } from "../../store/slices/gameSlice";

export const BUY_IN_MODAL = "BUY_IN_MODAL";

const BuyInModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state: RootState) => state.modal);
  const userState = useAppSelector((state: RootState) => state.user.user);
  const [buyInAmount, setBuyInAmount] = useState<number | number[]>(
    modalData.payload?.tableState.min_buy_in || 0
  );

  const [join] = useJoinMutation();

  const handleClose = () => {
    dispatch(hideActiveOverlay());
  };

  const handleBuyIn = async () => {
    try {
      await join({
        tableId: modalData.payload.roomId,
        balance: buyInAmount as number,
      });
      dispatch(hideActiveOverlay());
      dispatch(remove_low_balance_flag());
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      dispatch(hideActiveOverlay());
      dispatch(remove_low_balance_flag());
    };
  }, []);

  return (
    <Modal
      open={modalData.activeOverlay === BUY_IN_MODAL}
      onClose={handleClose}
    >
      <ModalContent>
        {modalData.payload && (
          <Box p={3}>
            Select Buy in Amount:
            <Slider
              value={buyInAmount}
              min={modalData.payload.tableState.min_buy_in}
              max={
                modalData.payload.tableState.max_buy_in > userState!.balance
                  ? userState!.balance
                  : modalData.payload.tableState.max_buy_in
              }
              valueLabelDisplay="auto"
              disabled={
                (userState?.balance || 0) <
                modalData.payload.tableState.min_buy_in
              }
              onChange={(e, value) => setBuyInAmount(value)}
            />
            <Button onClick={handleBuyIn} variant="contained" color="success">
              Join
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BuyInModal;
