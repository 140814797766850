import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import CountdownTimer from "./CountDownTimer";
import PlayerCards from "./playerCards";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
const StyledPlayerSeat = styled(Box)(() => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  background: "gray",
  border: "2px solid white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  color: "white",
  fontSize: "18px",
  fontWeight: "bold",
  userSelect: "none",
  position: "absolute",
  transformOrigin: "center",
  transform: "translate(-50%, -50%)",
}));

interface Iprops {
  player_id: number | undefined;
  balance: number | undefined;
  x: number;
  y: number;
  label: number | string;
  left: boolean;
}
const PlayerSeat: React.FC<Iprops> = ({
  player_id,
  balance,
  x,
  y,
  label,
  left,
}) => {
  const roundState = useAppSelector(
    (state: RootState) => state.game.roundState
  );

  const player = useAppSelector((state: RootState) =>
    state.game.gameState?.players?.find(
      (player) => player.player_id === player_id
    )
  );

  const user = useAppSelector((state: RootState) => state.user.user);

  const myCards = player_id === user?.id && roundState.myCards;
  const shownCards = roundState.playerCards.find(
    (player) => player.player_id === player_id
  )?.cards;

  return (
    <>
      <StyledPlayerSeat sx={{ left: x, top: y }}>
        <Tooltip title={label}>
          {label === "Empty Seat" ? (
            <Avatar alt="empty seat" sx={{ height: 60, width: 60 }} />
          ) : (
            <Avatar
              alt="player"
              sx={{ height: 60, width: 60 }}
              src={player?.player_avatar || ""}
            />
          )}
        </Tooltip>
        <div style={{ textAlign: "center" }}>
          {balance && `${Math.round(balance * 10) / 10}`}
        </div>
        {roundState.playerTurnId && roundState.playerTurnId === player_id && (
          <CountdownTimer serverTimestamp={Date.now()} />
        )}
        {roundState.previousPlayerDecision?.playerId &&
          roundState.previousPlayerDecision?.playerId === player_id && (
            <Box position="absolute" bottom={-40} color="black">
              <Typography fontWeight={700}>
                {roundState.previousPlayerDecision.decision === "Call" &&
                !roundState.previousPlayerDecision.betAmount
                  ? "Check"
                  : `${roundState.previousPlayerDecision?.decision} ${
                      roundState.previousPlayerDecision?.betAmount || ""
                    }`}
              </Typography>
            </Box>
          )}
        <PlayerCards
          left={left}
          shouldRender={player?.player_status === "playing"}
          playerCards={myCards || shownCards}
          playerStatus={player?.player_status}
        />
      </StyledPlayerSeat>
    </>
  );
};

export default PlayerSeat;
