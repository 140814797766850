import Typography from "@mui/material/Typography";
import CoverPaper from "../../components/CoverPaper";
import Box from "@mui/material/Box";

const Fairness: React.FC = () => {
  return (
    <CoverPaper>
      {" "}
      <Box
        width="100%"
        pt={{ md: "60px" }}
        px={{ lg: "60px", xl: "40px" }}
        display={"flex"}
        justifyContent={{ xs: "center" }}
        flexWrap={"wrap"}
        alignItems={{ md: "center" }}
        sx={{
          userSelect: "none",
          msUserSelect: "none",
          WebkitUserSelect: "none",
          ".MuiTypography-root": {
            fontFamily: "Butch",
          },
          ".MuiButton-text": {
            fontFamily: "Butch",
          },
        }}
        flexDirection="column"
      >
        <Typography variant="h4" component="h1">
          Provably Fair
        </Typography>
        <Box mt={2} textAlign="left" width={{ md: "500px", xs: "100%" }}>
          <Typography variant="h6" component="p">
            code for player card generation:
          </Typography>
          <Typography component="code">
            <Box
              whiteSpace={{ md: "pre", xs: "inherit" }}
              fontFamily="monospace"
            >
              def _startRound(self):{"\n"}{" "}
              self.publicOut(self.PublicOutId.NEWROUND){"\n"} for player in
              self.players:{"\n"} player.resetState() player.cards =
              (next(self._deck), next(self._deck))
            </Box>
          </Typography>
        </Box>
        <Box mt={2} textAlign="left" width={{ md: "500px", xs: "100%" }}>
          <Typography variant="h6" component="p">
            code for deck and card generation:
          </Typography>
          <Typography component="code">
            <Box
              whiteSpace={{ md: "pre", xs: "inherit" }}
              fontFamily="monospace"
            >
              __deck = [[rank, suit] for suit in Suit for rank in Rank]
              {"\n...\n"}
              def _deckIterator(self):{"\n"} ncards = len(self.players) * 2 + 5
              {"\n"}return iter(sample(self.__deck, ncards))
            </Box>
          </Typography>
        </Box>
        <Box mt={2} textAlign="left" width={{ md: "500px", xs: "100%" }}>
          <Typography variant="h6" component="p">
            Rake on all tables is 3.35% but will never be bigger than maxR
            determined on tables
          </Typography>
        </Box>
        <Box mt={2} textAlign="left" width={{ md: "500px", xs: "100%" }}>
          <Typography variant="h6" component="p">
            code for raking winners - each winner is based on the following
            formula - raked_amount = win_amount * total_rake / total_pot:
          </Typography>
          <Typography component="code">
            In Case of reaching end of round:
            <Box
              whiteSpace={{ md: "pre", xs: "inherit" }}
              fontFamily="monospace"
            >
              total_rake_to_take = win_took_decimal / total_pot * total_rake
              {"\n"}
              total_win = round(win_took_decimal - total_rake_to_take, 2){"\n"}
              win_split.money += total_win win_split.group_kickers = kickers
            </Box>
          </Typography>
          <Typography component="code">
            In Case of premature winning (all other players folded):
            <Box
              whiteSpace={{ md: "pre", xs: "inherit" }}
              fontFamily="monospace"
            >
              rake_percent = table.rake_percentage / 100{"\n"}
              max_rake = table.max_rake {"\n"}won = Decimal(sum(self.pot_size))
              {"\n"}
              total_rake = min(max_rake, won * rake_percent){"\n"}
              winner, =self.players.getNotFoldedPlayers(){"\n"}
              winner.money += won - total_rake
            </Box>
          </Typography>
        </Box>
      </Box>
    </CoverPaper>
  );
};

export default Fairness;
