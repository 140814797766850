import { Howl } from "howler";

const callSound = new Howl({
    src: ["/assets/sounds/call.mp3"],
    volume: 1.0,
})

const cardsDealtSound = new Howl({
    src: ["/assets/sounds/cardsDealt.wav"],
    volume: 1.0,
})

const raiseSound = new Howl({
    src: ["/assets/sounds/raise.mp3"],
    volume: 1.0,
})

const allInSound = new Howl({
    src: ["/assets/sounds/allIn.mp3"],
    volume: 1.0,
})

const boardSound = new Howl({
    src: ["/assets/sounds/board.mp3"],
    volume: 1.0,
})

const winSound = new Howl({
    src: ["/assets/sounds/win.mp3"],
    volume: 1.0,
})

const checkSound = new Howl({
    src: ["/assets/sounds/check.mp3"],
    volume: 1.0,
})

const foldSound = new Howl({
    src: ["/assets/sounds/fold.mp3"],
    volume: 1.0,
})

export const playCallSound = () => {
    callSound.stop();
    callSound.seek(0);
    callSound.play();
}
export const playCardsDealtSound = () => {
    cardsDealtSound.stop();
    cardsDealtSound.seek(0);
    cardsDealtSound.play();
}
export const playRaiseSound = () => {
    raiseSound.stop();
    raiseSound.seek(0);
    raiseSound.play();
}
export const playAllInSound = () => {
    allInSound.stop();
    allInSound.seek(0);
    allInSound.play();
}
export const playBoardSound = () => {
    boardSound.stop();
    boardSound.seek(0);
    boardSound.play();
}
export const playWinSound = () => {
    winSound.stop();
    winSound.seek(0);
    winSound.play();
}
export const playCheckSound = () => {
    checkSound.stop();
    checkSound.seek(0);
    checkSound.play();
}
export const playFoldSound = () => {
    foldSound.stop();
    foldSound.seek(0);
    foldSound.play();
}