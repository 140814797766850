import React from "react";
import ModalContent from "../ModalContent";
import { Modal, Box } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/store";
import { hideActiveOverlay } from "../../store/slices/modalSlice";
import Button from "@mui/material/Button";

export const ACCEPT_TRADE_MODAL = "ACCEPT_TRADE_MODAL";

const AcceptTradeModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state: RootState) => state.modal);
  const handleClose = () => {
    dispatch(hideActiveOverlay());
  };

  return (
    <Modal
      open={modalData.activeOverlay === ACCEPT_TRADE_MODAL}
      onClose={handleClose}
    >
      <ModalContent>
        <Box px={1} mb={1}>
          {modalData.payload && !modalData.payload.isManual
            ? "We have sent you an automatic trade offer, after you accept it you have to check trade offer status through trade history tab."
            : "For offers over 100$ our team will manually send you offer and once you accept it they will update the balance."}
        </Box>
        <Box>
          <Button variant="contained" onClick={handleClose} color="success">
            Close
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AcceptTradeModal;
