import Box from "@mui/material/Box";
import { cardEnumMap } from "../../Enums/cardEnums";
import { suitEnumMap } from "../../Enums/cardEnums";
import { memo } from "react";

interface Iprops {
  left: boolean;
  shouldRender: boolean;
  playerCards: false | number[][] | undefined;
  playerStatus: "playing" | "folded" | "waiting" | undefined;
}

const PlayerCards: React.FC<Iprops> = ({
  left,
  shouldRender,
  playerStatus,
  playerCards,
}) => {
  return (
    <Box
      display={shouldRender ? "flex" : "none"}
      position="absolute"
      sx={[
        left ? { left: -105 } : { right: -105 },
        playerStatus === "folded" && { opacity: 0.7 },
      ]}
    >
      <Box
        component="img"
        src={
          playerCards
            ? `/assets/cards/front/${cardEnumMap.get(
                playerCards[0][0]
              )}${suitEnumMap.get(playerCards[0][1])}.png`
            : "/assets/cards/back/backofcards.png"
        }
        width={75}
        marginRight={-6}
      />
      <Box
        component="img"
        src={
          playerCards
            ? `/assets/cards/front/${cardEnumMap.get(
                playerCards[1][0]
              )}${suitEnumMap.get(playerCards[1][1])}.png`
            : "/assets/cards/back/backofcards.png"
        }
        width={75}
      />
    </Box>
  );
};

export default memo(PlayerCards);
