import React from "react";
import Box from "@mui/material/Box";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import CoverPaper from "../../components/CoverPaper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { useLogoutMutation } from "../../api/profileApi";
import { logout } from "../../store/slices/userSlice";
import { useAppDispatch } from "../../store/hooks";

const Account: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 899px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoutApi] = useLogoutMutation();
  async function logOut() {
    try {
      logoutApi();
      dispatch(logout());
      navigate("/");
    } catch (e) {}
  }
  return (
    <CoverPaper>
      <Box display={{ md: "flex" }} pt="30px" px={{ lg: "60px" }}>
        <Tabs
          orientation={mobile ? "horizontal" : "vertical"}
          value={location.pathname.split("/").pop()}
          sx={{ mr: { md: "30px" }, mb: { xs: "30px", md: 0 } }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            value="profile"
            component={Link}
            to="/accounts/profile"
            label={
              <Typography variant="body2" fontSize={12}>
                General Profile
              </Typography>
            }
          />
          <Tab
            component={Link}
            value="inventory"
            to="/accounts/inventory"
            label={
              <Typography variant="body2" fontSize={12}>
                Inventory
              </Typography>
            }
          />
          <Tab
            component={Link}
            value="depositHistory"
            to="/accounts/depositHistory"
            label={
              <Typography variant="body2" fontSize={12}>
                Deposit History
              </Typography>
            }
          />
          <Tab
            component={Link}
            value="withdrawHistory"
            to="/accounts/withdrawHistory"
            label={
              <Typography variant="body2" fontSize={12}>
                Withdraw History
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="body2" fontSize={12}>
                Logout
              </Typography>
            }
            onClick={() => logOut()}
          />
        </Tabs>
        <Outlet />
      </Box>
    </CoverPaper>
  );
};

export default Account;
