import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import InvertedButton from "../../components/InvertedButton";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IInventory } from "../../types/withdrawTypes";
import Typography from "@mui/material/Typography";
import { RootState } from "../../store/store";
import { useWithdrawOfferMutation } from "../../api/profileApi";
import { toast } from "react-toastify";
import { updateBalance } from "../../store/slices/userSlice";

interface Iprops {
  items: IInventory[];
  afterSubmit: () => void;
}
const MarketplaceLowOverlay: React.FC<Iprops> = ({ items, afterSubmit }) => {
  const user = useAppSelector((state: RootState) => state.user.user);
  const dispatch = useAppDispatch();
  const [withdrawOffer] = useWithdrawOfferMutation();
  const sum = items.reduce<number>(
    (acc, previousVal) => acc + parseFloat(previousVal.fields.price || "0"),
    0
  );

  const handleOfferClick = async () => {
    try {
      const newBalance = await withdrawOffer(
        items.map((item) => item.pk)
      ).unwrap();
      dispatch(updateBalance(parseFloat(newBalance.new_balance)));
      toast.success("Withdraw successfully sent");
      afterSubmit();
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const renderAppropriateText = () => {
    if (!user) return "You need to be logged in to withdraw";
    if (user.balance < sum) return "You don't have enough coins to withdraw";
    return `you will pay ${sum.toFixed(2)} coins`;
  };

  return (
    <Drawer
      sx={{
        width: "100%",
        height: "100px",
      }}
      variant="permanent"
      anchor="bottom"
    >
      <Box
        sx={{
          background: "#add8e6",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Box>
          <Typography>{renderAppropriateText()}</Typography>
        </Box>
        <Box
          sx={{
            ml: {
              xs: 0,
              sm: "auto",
            },
          }}
        >
          <InvertedButton
            actColor="white"
            bgColor="success.main"
            sx={{ width: "200px" }}
            onClick={handleOfferClick}
            disabled={!user || user.balance < sum}
          >
            Withdraw
          </InvertedButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MarketplaceLowOverlay;
