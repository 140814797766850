import Account from "./routes/Account";
import Home from "./routes/Home";
import Rooms from "./routes/Rooms";
import Root from "./routes/Root";
import Table from "./routes/Table";
import Profile from "./routes/Account/Profile";
import TradeHistory from "./routes/Account/TradeHistory";
import WithdrawHistory from "./routes/Account/WithdrawHistory";
import Marketplace from "./routes/Marketplace";
import Fairness from "./routes/Fairness";
import FAQs from "./routes/FAQs";
import Inventory from "./routes/Account/Inventory";
import { useEffect, useState } from "react";
import { useGetUserMutation } from "./api/profileApi";
import { useAppDispatch } from "./store/hooks";
import { authenticate } from "./store/slices/userSlice";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import Betapage from "./routes/Betapage";
import Loading from "./components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [login] = useGetUserMutation();
  const dispatch = useAppDispatch();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  async function checkAuth() {
    try {
      const user = await login().unwrap();
      if (!user.authenticated) return;
      dispatch(authenticate(user.user));
    } catch {
    } finally {
      setTimeout(() => {
        setIsAuthChecked(true);
      }, 200);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="beta" element={<Betapage />} />
        <Route path="faq" element={<FAQs />} />
        <Route path="tables" element={<Rooms />} />
        <Route path="marketplace" element={<Marketplace />} />
        <Route path="table/:id" element={<Table />} />
        <Route path="accounts" element={<Account />}>
          <Route path="profile" element={<Profile />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="depositHistory" element={<TradeHistory />} />
          <Route path="withdrawHistory" element={<WithdrawHistory />} />
        </Route>
        <Route path="fairness" element={<Fairness />} />
      </Route>
    )
  );
  return (
    <div className="App">
      {!isAuthChecked ? <Loading /> : <RouterProvider router={router} />}
      <ToastContainer />
    </div>
  );
}

export default App;
