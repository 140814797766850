import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface modalSliceState  {
    activeOverlay: string | undefined,
    payload: any
}

const initialState: modalSliceState = {
    activeOverlay: undefined,
    payload: undefined
}
export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showActiveOverlay: (state, action: PayloadAction<{overlay: string, payload: any}>) => {
            state.activeOverlay = action.payload.overlay
            state.payload = action.payload.payload
        },
        hideActiveOverlay: (state) => {
            state.activeOverlay = undefined
            state.payload = undefined
        }
    }
})

export const {showActiveOverlay, hideActiveOverlay} = modalSlice.actions

export default modalSlice.reducer;