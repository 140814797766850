import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const getCsrfCookie =() => {
    const csrfCookie = document.cookie.split('; ').filter(row => row.startsWith('csrftoken=')).map(c=>c.split('=')[1])[0]

    return csrfCookie || ''
}

const baseApi = createApi({
    baseQuery: fetchBaseQuery({baseUrl: '/api/',
    prepareHeaders: (headers) => {
        headers.set('X-CSRFToken', getCsrfCookie())
    }
}),
    keepUnusedDataFor: 2,
    endpoints: () => ({}),
    
});

export default baseApi;