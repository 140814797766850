import React from "react";
import Container from "@mui/material/Container";
import CoverPaper from "../../components/CoverPaper";
import HomeHero from "../../components/HomeHero";

const Home: React.FC = () => {
  return (
    <CoverPaper elevation={0}>
      <Container maxWidth="xl">
        <HomeHero />
      </Container>
    </CoverPaper>
  );
};

export default Home;
