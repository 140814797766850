import React from "react";
import Box from "@mui/material/Box";
interface Iprops {
  chipX: number;
  chipY: number;
  playerBet: number | undefined;
  playerId: number | undefined;
}
const PlayerBets: React.FC<Iprops> = ({
  chipX,
  chipY,
  playerBet,
  playerId,
}) => {
  function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
  if (!playerBet || !playerId) return <></>;
  let testAmount = playerBet;
  const fivehundreds = Math.floor(testAmount / 500);
  testAmount = testAmount % 500;
  const hundreds = Math.floor(testAmount / 100);
  testAmount = testAmount % 100;
  const fifties = Math.floor(testAmount / 50);
  testAmount = testAmount % 50;
  const tens = Math.floor(testAmount / 10);
  testAmount = Math.floor(testAmount % 10);
  return (
    <Box position="absolute" left={chipX} top={chipY} width={100}>
      <Box position="relative" width={100} height={50}>
        {[...Array(testAmount)].map(() => {
          return (
            <Box
              left={getRandomArbitrary(10, 50)}
              top={getRandomArbitrary(10, 20)}
              component="img"
              position="absolute"
              src="/assets/chips/1.webp"
              width={50}
              key={Math.random()}
            />
          );
        })}
        {[...Array(tens)].map(() => {
          return (
            <Box
              left={getRandomArbitrary(10, 50)}
              top={getRandomArbitrary(10, 20)}
              component="img"
              position="absolute"
              src="/assets/chips/10.webp"
              width={50}
              key={Math.random()}
            />
          );
        })}
        {[...Array(fifties)].map(() => {
          return (
            <Box
              left={getRandomArbitrary(10, 50)}
              top={getRandomArbitrary(10, 20)}
              component="img"
              position="absolute"
              src="/assets/chips/50.webp"
              width={50}
              key={Math.random()}
            />
          );
        })}
        {[...Array(hundreds)].map(() => {
          return (
            <Box
              left={getRandomArbitrary(10, 50)}
              top={getRandomArbitrary(10, 20)}
              component="img"
              position="absolute"
              src="/assets/chips/100.webp"
              width={50}
              key={Math.random()}
            />
          );
        })}
        {[...Array(fivehundreds)].map((data, index) => {
          return (
            <Box
              left={getRandomArbitrary(10, 50)}
              top={getRandomArbitrary(10, 20)}
              component="img"
              position="absolute"
              src="/assets/chips/500.webp"
              width={50}
              key={Math.random()}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default React.memo(PlayerBets);
