import React from "react";
import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { IplayerBet, ItablePlayers } from "../../types/tableTypes";
import PlayerSeat from "./PlayerSeat";
import { suitEnumMap, cardEnumMap } from "../../Enums/cardEnums";
import PlayerBets from "./PlayerBet";
import Pot from "./Pot";

const PokerStyledPaper = styled(Paper)(() => ({
  margin: "80px auto 0",
  width: "1100px",
  height: "500px",
  backgroundImage: "url('/assets/table.webp')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "100% 100%",
  backgroundColor: "transparent",
  position: "relative",
  borderRadius: "",
}));

const StyledBoardCards = styled(Box)(() => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  gap: "10px",
}));

const PokerTable: React.FC<{
  players: Array<ItablePlayers> | undefined;
  board: Array<Array<number>>;
  playerBets: Array<IplayerBet> | undefined;
  pot: number;
}> = ({ players, board, playerBets, pot }) => {
  const numPlayerSeats = 8;
  const playerSeats = [];

  const angleStep = (2 * Math.PI) / numPlayerSeats;
  const playerBetLocations = [];
  for (let i = 0; i < numPlayerSeats; i++) {
    const angle = i * angleStep;
    const horizontalRadius = 560;
    const verticalRadius = 290;
    const x = horizontalRadius * Math.cos(angle) + 550;
    const y = verticalRadius * Math.sin(angle) + 250;
    const chipX = 450 * Math.cos(angle) + 500;
    const chipY = 180 * Math.sin(angle) + 220;
    const label = players && players[i] ? players[i].player_name : "Empty Seat";
    const balance = players && players[i] ? players[i].balance : undefined;
    const player_id = players && players[i] ? players[i].player_id : undefined;
    playerSeats.push({ x, y, label, balance, player_id });
    playerBetLocations.push({ chipX, chipY, player_id });
  }
  return (
    <PokerStyledPaper elevation={0}>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "30%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Pot potSize={pot} />
      </Box>
      <StyledBoardCards>
        {board.map((card) => {
          return (
            <Box
              key={`cardEnum-${card[0]}-SuitEnum-${card[1]}`}
              src={`/assets/cards/front/${cardEnumMap.get(
                card[0]
              )}${suitEnumMap.get(card[1])}.png`}
              component="img"
              width={80}
            />
          );
        })}
      </StyledBoardCards>
      {playerSeats.map((seat, index) => (
        <PlayerSeat key={index} {...seat} left={[0, 1, 7].includes(index)} />
      ))}
      {playerBetLocations.map((bet, index) => (
        <PlayerBets
          chipX={bet.chipX}
          chipY={bet.chipY}
          playerId={bet.player_id}
          playerBet={
            playerBets?.find((bets) => bets.player_id === bet.player_id)
              ?.paid_amount
          }
          key={bet.player_id || `free-${index}`}
        />
      ))}
    </PokerStyledPaper>
  );
};

export default PokerTable;
