import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ItableState, 
  IroundState, ItablePlayers, 
  IplayerBet, 
  IplayerDecision, 
  IplayerWin } from '../../types/tableTypes'
  import * as sounds from '../../components/sounds'

export interface gameSliceState {
  gameState: ItableState | undefined,
  roundState: IroundState
}

const initialState: gameSliceState = {
  gameState: undefined,
  roundState: {
    playerBets: [],
    myCards: undefined,
    playerTurnId: undefined,
    to_call: undefined,
    action: undefined,
    board: [],
    playerCards: [],
    previousPlayerDecision: undefined,
    pot: 0,
  }
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    append_initial_state: (state, action: PayloadAction<ItableState>) => {
      state.gameState = action.payload
      const reconnect_data = JSON.parse(action.payload.reconnect_data)
      if (reconnect_data) {
        state.roundState.to_call = parseInt(reconnect_data.to_call)
        state.roundState.pot = parseInt(reconnect_data.pot)
        state.roundState.board = reconnect_data.board ? JSON.parse(reconnect_data.board) : []
        state.roundState.myCards = reconnect_data.player_cards ? JSON.parse(reconnect_data.player_cards) : []
        state.roundState.playerTurnId = parseInt(reconnect_data.current_player)
        state.roundState.action = 'action'
        const folded_players = reconnect_data.folded_players
        if (folded_players) {
          const folded_player_array = JSON.parse(folded_players)
          folded_player_array.forEach((playerId: number) => {
            if (state.gameState) {
              const index = state.gameState.players.findIndex(player => player.player_id === playerId)
              if (index > -1) {
                state.gameState.players[index].player_status = 'folded'
              }
            }
          })
        }
      }
    },

    player_joined: (state, action: PayloadAction<ItablePlayers>) => {
      state.gameState?.players.push(action.payload)
    },

    player_left: (state, action: PayloadAction<number>) => {
      if (state.gameState) {
        state.gameState.players = state.gameState?.players.filter(player => player.player_id !== action.payload)
      }
    },

    player_paid: (state, action: PayloadAction<IplayerBet>) => {
      const index = state.roundState?.playerBets?.findIndex(playerBet => playerBet.player_id === action.payload.player_id)
      if (index !== undefined && index > -1 && state.roundState && state.roundState.playerBets) {
        state.roundState.playerBets[index].paid_amount += action.payload.paid_amount
        state.roundState.previousPlayerDecision = {
          playerId: action.payload.player_id,
          decision: action.payload.action,
          betAmount: action.payload.paid_amount
        }

        if(state.gameState) {
          const index = state.gameState.players.findIndex(player => player.player_id === action.payload.player_id)
  
          if (index > -1) {
            state.gameState.players[index].balance -= action.payload.paid_amount
          }
        }

        if (action.payload.action === 'Call') {
          if (!action.payload.paid_amount) {
            sounds.playCheckSound()
          } else {
            sounds.playCallSound()
          }
        }
  
        if (action.payload.action === 'Raise') {
          sounds.playRaiseSound()
        }
  
        if (action.payload.action === 'All in') {
          sounds.playAllInSound()
        }
  
        if (action.payload.action === 'Fold') {
          sounds.playFoldSound()
        }

        return
      }

      if (state.roundState && state.roundState.playerBets) {
        state.roundState.playerBets.push(action.payload)

        state.roundState.previousPlayerDecision = {
          playerId: action.payload.player_id,
          decision: action.payload.action,
          betAmount: action.payload.paid_amount
        }

        if(state.gameState) {
          const index = state.gameState.players.findIndex(player => player.player_id === action.payload.player_id)
  
          if (index > -1) {
            state.gameState.players[index].balance -= action.payload.paid_amount
          }
        }

      }

      if (action.payload.action === 'Call') {
        if (!action.payload.paid_amount) {
          sounds.playCheckSound()
          console.log('should play check')
        } else {
          sounds.playCallSound()
        }
      }

      if (action.payload.action === 'Raise') {
        sounds.playRaiseSound()
      }

      if (action.payload.action === 'All in') {
        sounds.playAllInSound()
      }

      if (action.payload.action === 'Fold') {
        sounds.playFoldSound()
      }
    },

    player_won: (state, action: PayloadAction<IplayerWin>) => {
      if (state.gameState) {
        const index = state.gameState.players.findIndex(playerBet => playerBet.player_id === action.payload.player_id)  
        state.roundState.previousPlayerDecision = undefined
        if (index > -1 ) {
          state.gameState.players[index].balance += action.payload.money_won
        }
        sounds.playWinSound()
      }
      return 
    },

    player_fold: (state, action: PayloadAction<number>) => {
      if (state.roundState && state.gameState) {
        state.roundState.previousPlayerDecision = {
          decision: 'Fold',
          playerId: action.payload,
          betAmount: undefined
        }
        const index = state.gameState.players.findIndex(player => player.player_id === action.payload)
        state.gameState.players[index].player_status = 'folded'
        sounds.playFoldSound()
      }
      return
    },

    player_decision: (state, action: PayloadAction<IplayerDecision>) => {
      state.roundState.playerTurnId = action.payload.player_id
      state.roundState.to_call = action.payload.to_call
      state.roundState.action = 'action'
    },

    player_choice_required: (state, action: PayloadAction<number>) => {
      state.roundState.playerTurnId = action.payload
      state.roundState.action = 'choice'
    },
    cards_dealt: (state, action: PayloadAction<Array<Array<number>>>) => {
      state.roundState.myCards = action.payload
      localStorage.setItem('cards', JSON.stringify(action.payload))
      sounds.playCardsDealtSound()
    },

    new_turn: (state, action: PayloadAction<{board: Array<Array<number>>, pot: number}>) => {
      state.roundState.board = action.payload.board
      state.roundState.pot = action.payload.pot
      state.roundState.previousPlayerDecision = undefined
      state.roundState.playerBets = []
      sounds.playBoardSound()
    },

    new_round_started: (state, action: PayloadAction<any>) => {
      state.roundState = {
        playerBets: [],
        myCards: undefined,
        playerTurnId: undefined,
        to_call: undefined,
        action: undefined,
        board: [],
        playerCards: [],
        previousPlayerDecision: undefined,
        pot: 0
      }
      state.gameState?.players.forEach(player => {player.player_status = 'playing'})
    },
    reset_state: (state) => {
      state.roundState = {
        playerBets: [],
        myCards: undefined,
        playerTurnId: undefined,
        to_call: undefined,
        action: undefined,
        board: [],
        playerCards: [],
        previousPlayerDecision: undefined,
        pot: 0
      }
      state.gameState = undefined
    },
    public_card_show: (state, action: PayloadAction<{player_id: number, cards: Array<Array<number>>}>) => {
      state.roundState.playerCards.push(action.payload)
    },
    low_balance: (state) => {
      if (state.gameState) {
        state.gameState.self_low_balance = true;
      }
    },
    remove_low_balance_flag: (state) => {
      if (state.gameState) {
        state.gameState.self_low_balance = false;
      }
    },
    player_balance_update: (state, action: PayloadAction<{player_id: number, balance: number}>) => {
      const index = state.gameState?.players.findIndex(player => player.player_id === action.payload.player_id)
      if (index !== undefined && index > -1 && state.gameState) {
        state.gameState.players[index].balance = action.payload.balance
      }
      return 
    }
  },
})

export const { append_initial_state, player_joined, player_left, player_paid, reset_state, remove_low_balance_flag } = gameSlice.actions

export default gameSlice.reducer