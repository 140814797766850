import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGetWithdrawHistoryQuery } from "../../../api/profileApi";
import Loading from "../../../components/Loading";
import { useAppDispatch } from "../../../store/hooks";
import { WITHDRAW_DETAILS_MODAL } from "../../../components/WithdrawDetailsModal/WithdrawDetailsModal";
import WithdrawDetailsModal from "../../../components/WithdrawDetailsModal";
import { showActiveOverlay } from "../../../store/slices/modalSlice";
import Typography from "@mui/material/Typography";
import { withdrawStatus } from "../../../Enums/withdrawStatusEnum";

const WithdrawHistory: React.FC = () => {
  const { isLoading, data } = useGetWithdrawHistoryQuery();
  const dispatch = useAppDispatch();
  return (
    <TableContainer component={Paper} sx={{ minWidth: 320, maxWidth: 800 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Created At</Typography>
            </TableCell>
            <TableCell>
              <Typography>Balance</Typography>
            </TableCell>
            <TableCell>
              <Typography>Status</Typography>
            </TableCell>
            <TableCell>
              <Typography>Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(isLoading || !data) && (
            <TableRow>
              <TableCell>
                <Loading />
              </TableCell>
            </TableRow>
          )}
          {data &&
            data.withdraws.map((trade: any) => (
              <TableRow key={trade.temp_id}>
                <TableCell>
                  <Typography>
                    {new Date(trade.created_at).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>-{trade.money || "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{withdrawStatus.get(trade.status)}</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      dispatch(
                        showActiveOverlay({
                          overlay: WITHDRAW_DETAILS_MODAL,
                          payload: { trade },
                        })
                      )
                    }
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <WithdrawDetailsModal />
    </TableContainer>
  );
};

export default WithdrawHistory;
