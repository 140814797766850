import React from "react";
import { Card, CardContent, Typography, Grid, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Itable } from "../../types/tableTypes";
import { Link } from "react-router-dom";

interface TableCardProps {
  table: Itable;
}

const TableCard: React.FC<TableCardProps> = ({ table }) => {
  const {
    table_name,
    small_blind,
    big_blind,
    min_buy_in,
    max_buy_in,
    max_players,
    player_count,
    id,
    max_rake,
    closed,
  } = table;

  return (
    <Card
      component={Link}
      to={`/table/${id}`}
      sx={{
        textDecoration: "none",
        background: "whitesmoke",
        maxWidth: "450px",
        width: { xs: "100%", md: "30%" },
        minWidth: { md: "450px" },
        opacity: closed ? 0.7 : 1,
        pointerEvents: closed ? "none" : "auto",
        cursor: closed ? "not-allowed" : "pointer",
      }}
    >
      <CardContent
        sx={{ ".MuiTypography-root": { color: "#000" }, textAlign: "center" }}
      >
        <Typography variant="h5">{table_name}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>
              SB/BB: {small_blind}/{big_blind}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>
              MinB/MaxB: {min_buy_in}/{max_buy_in}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>maxR: {max_rake}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {Array.from({ length: max_players }).map((_, index) => (
            <Grid item key={`${id}_${index}`} xs={1.5}>
              <Avatar
                sx={index < player_count ? { bgcolor: "success.main" } : {}}
              >
                <PersonIcon />
              </Avatar>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TableCard;
