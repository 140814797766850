import CoverPaper from "../../components/CoverPaper";
import Typography from "@mui/material/Typography";

const Betapage: React.FC = () => {
  return (
    <CoverPaper
      sx={{
        backgroundImage: "url(/assets/betaHero.png)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3" component={"h1"}>
        CS2Aces is currently in the Closed Beta stage. To become a part of it,
        join our Discord by clicking the button below.
      </Typography>
      <a href="https://discord.gg/N6u6ZRnHxR" target="_blank" rel="noreferrer">
        <img src="/assets/discord.png" width={150} alt="discord link logo" />
      </a>
    </CoverPaper>
  );
};

export default Betapage;
