import Container from "@mui/material/Container";
import CoverPaper from "../../components/CoverPaper";
import Box from "@mui/material/Box";
import { useGetTablesQuery } from "../../api/tableApi";
import TableCard from "./TableCard";

const Rooms: React.FC = () => {
  const { data } = useGetTablesQuery(
    {},
    {
      pollingInterval: 3000,
    }
  );
  const tables = data?.tables || [];
  return (
    <CoverPaper>
      <Container maxWidth="xl" sx={{ pt: "30px" }}>
        <Box display="flex" alignItems={"center"} justifyContent={"center"}>
          <Box
            display="flex"
            gap={2}
            flexWrap="wrap"
            width={{ md: "60%", xs: "100%" }}
          >
            {tables && tables.map((table) => <TableCard table={table} />)}
          </Box>
          <Box width={{ md: "45%" }} display={{ xs: "none", md: "block" }}>
            <Box
              component={"img"}
              src="/assets/tablesbanner.png"
              width={"100%"}
            />
          </Box>
        </Box>
      </Container>
    </CoverPaper>
  );
};

export default Rooms;
