import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import PokerTable from "./PokerTable";
import { useAppDispatch } from "../../store/hooks";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import InvertedButton from "../../components/InvertedButton";
import ActionBar from "./ActionBar";
import Box from "@mui/material/Box";
import { reset_state } from "../../store/slices/gameSlice";
import { showActiveOverlay } from "../../store/slices/modalSlice";
import BuyInModal from "../../components/BuyInModal";
import { BUY_IN_MODAL } from "../../components/BuyInModal/BuyInModal";
import { useNavigate } from "react-router-dom";

const Table: React.FC = () => {
  const { id: roomId } = useParams();
  const navigate = useNavigate();
  const tableState = useAppSelector((state: RootState) => state.game.gameState);
  const needModal = useAppSelector(
    (state: RootState) => state.game.gameState?.self_low_balance
  );
  const roundState = useAppSelector(
    (state: RootState) => state.game.roundState
  );
  const userState = useAppSelector((state: RootState) => state.user.user);

  const dispatch = useAppDispatch();

  const openBuyInModal = useCallback(() => {
    dispatch(
      showActiveOverlay({
        overlay: BUY_IN_MODAL,
        payload: { tableState, roomId },
      })
    );
  }, [dispatch, tableState, roomId]);

  useEffect(() => {
    if (needModal) {
      openBuyInModal();
    }
  }, [needModal, openBuyInModal]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://${window.location.host}/tablews/${roomId}/`
    );
    ws.onerror = (err) => {
      console.log(err);
    };

    ws.onmessage = (messageEvent) => {
      const wsMessage = JSON.parse(messageEvent.data);
      if (wsMessage.event) {
        dispatch({
          type: `game/${wsMessage.event}`,
          payload: wsMessage.payload,
        });
      }

      console.log(messageEvent);
    };

    ws.onclose = (closeEvent) => {
      console.log(closeEvent);
    };
    return () => {
      ws.close();
      dispatch(reset_state());
    };
  }, [roomId, dispatch]);

  return (
    <>
      <Box
        height={"100vh"}
        sx={{
          backgroundImage: "url('/assets/hero.jpeg')",
          backgroundSize: "cover",
          py: "10px",
        }}
      >
        {userState &&
          roomId &&
          tableState?.players.find(
            (player) => player.player_id === userState?.id
          ) && (
            <InvertedButton
              bgColor="error.main"
              actColor="#fff"
              onClick={() => navigate("/tables")}
            >
              Leave Table
            </InvertedButton>
          )}
        {userState &&
          roomId &&
          !tableState?.players.find(
            (player) => player.player_id === userState?.id
          ) && (
            <InvertedButton
              bgColor="success.main"
              actColor="#fff"
              onClick={openBuyInModal}
            >
              Take a seat
            </InvertedButton>
          )}

        <PokerTable
          players={tableState?.players}
          board={roundState.board}
          playerBets={roundState.playerBets}
          pot={roundState.pot}
        />
        {tableState &&
          userState &&
          userState.id === roundState.playerTurnId && (
            <ActionBar
              tableId={roomId || ""}
              type={roundState.action}
              toCall={roundState.to_call}
              bigBlind={tableState.big_blind}
              balance={
                tableState.players.find(
                  (player) => player.player_id === userState?.id
                )?.balance || 0
              }
              stake={
                roundState.playerBets?.find(
                  (bets) => bets.player_id === userState.id
                )?.paid_amount
              }
            />
          )}
      </Box>
      <BuyInModal />
    </>
  );
};

export default Table;
