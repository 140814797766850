import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "react-router-dom";
import Typography from "@mui/material/Typography";
import { InventoryItem } from "../../../types/inventoryTypes";
interface Iprops {
  items: InventoryItem[];
}
const LowOverlay: React.FC<Iprops> = ({ items }) => {
  const sum = items.reduce<number>(
    (acc, previousVal) => acc + parseFloat(previousVal.price || "0"),
    0
  );

  return (
    <Drawer
      sx={{
        width: "100%",
        height: "100px",
      }}
      variant="permanent"
      anchor="bottom"
    >
      <Box
        sx={{
          background: "#5191a6",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Box>
          {sum < 100 && (
            <Typography>you will receive {sum.toFixed(2)} coins</Typography>
          )}
          {sum >= 100 && (
            <Typography>
              For trade worth over 100 coins please write to us in discord
              server
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            ml: {
              xs: 0,
              sm: "auto",
            },
          }}
        >
          <Button
            color="success"
            variant="contained"
            sx={{ width: "200px" }}
            component={"a"}
            href="https://steamcommunity.com/tradeoffer/new/?partner=1023507744&token=bQWi4I1W"
            target="_blank"
            rel="noreferrer"
            disabled={sum >= 100}
          >
            Deposit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LowOverlay;
