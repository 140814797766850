import Container from "@mui/material/Container";
import CoverPaper from "../../components/CoverPaper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function BasicAccordion() {
  return (
    <CoverPaper
      elevation={0}
      sx={{ ".MuiTypography-root": { fontFamily: "Segoe UI" } }}
    >
      <Container maxWidth="xl">
        <Typography variant="h4" component="h1" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is CS2Aces?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              CS2Aces is a marketplace dedicated to Counter-Strike items
              (skins). We provide users with a secure and comfortable platform
              to buy, sell, and exchange their skins at low fees. Additionally,
              we offer our users the opportunity to enjoy their time at poker
              tables, where they can win new skins to enhance their CS2
              experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How can I exchange items?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              CS2Aces supplies users with credits for their items. The
              Administration at CS2Aces assigns a value to each item. Users can
              utilize these credits to purchase items from the marketplace page.
              Item prices are determined based on a range of general prices and
              are regularly updated. Before engaging in a trade with us on the
              Inventory page, every user can preview the amount of credits they
              will receive for their item. The Marketplace page is continually
              updated with a variety of skins daily, ensuring users always have
              the opportunity to choose from a diverse selection when acquiring
              new items.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>How Can I trust you my items?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Trusting CS2Aces ultimately relies on your personal choice. On our
              end, we assure our users the safety of their transactions, both
              for items and credits on their balance. We maintain transparent
              communication with our customers and operate with openness. There
              are no hidden fees or undisclosed information such as cookies.
              User-provided information remains confidential, and we do not have
              the authority to use it. Our commitment is to keep our trading and
              gamified system under control, promptly addressing any issues that
              may arise. We diligently record trades and games, allowing
              customers to reach out in uncertain situations. Our support team
              is readily available to provide all necessary information and
              assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>What about Refunds & Fees?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Refunds may be unavailable if a user has provided incorrect
              payment information or decides not to use our site after selling
              their items here. For issues related to our trading and gamified
              system, CS2Aces will issue a refund if the problem is confirmed by
              staff members. In such situations, customers can always contact
              our support and request a refund. CS2Aces is not obligated to
              recover losses in the case of an API scam. We take measures to
              secure users from API scams. If you ignore all security messages
              and inadvertently confirm offers, CS2Aces will not be able to
              prevent you from an API scam. You have 7 (seven) days to inform us
              about the refund. You can still contact us after 7 days pass, but
              the refund may not be implemented immediately. The item refund
              system may take up to 8 (eight) days for the item trade lock to
              end.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>Fees:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Any fees and commissions paid by users are non-returnable. Users
              will be notified about the fee size. CS2Aces will not refund fees
              that have already been paid. Under these Terms of Use, the User
              agrees to pay and is responsible for all applicable charges and
              fees for services. There are no hidden fees; imposed fees on
              cs2aces.com will be clearly mentioned in the withdraw
              (marketplace) section. All fees owed by the User to third parties
              are not covered by these Terms of Use. However, any fees owed by
              cs2aces.com to third parties related to transferring funds to a
              Personal account are the responsibility of the User. If
              information about refunds and fees is not fully provided here,
              please feel free to contact us.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography>Item Pricing:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Item prices are determined based on a range of general prices and
              are regularly updated. Before engaging in a trade with us on the
              Inventory page, every user can preview the amount of credits they
              will receive for their item. If one of your items doesn't appear
              on the page or lacks a price, it is likely categorized as
              invaluable due to its rarity or an unstable price. To make such an
              item available on the site, please report it on our Discord
              channel. If you are certain about a price inaccuracy, kindly
              submit a request for your item's price change on our Discord
              channel. Unfortunately, the price adjustment does not depend on
              us. Whether the prices of your items decrease or increase, it will
              always be based on the general prices of skins.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography>
              Why does my withdraw take longer than usual?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Currently, we rely on a manual withdrawal system, ensuring that
              users have legitimately purchased their items and will receive
              them without any issues. All withdrawals typically take between 2
              minutes and a maximum of 6 hours.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography>What if I have found a bug?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We strive to address every bug on our site, but if you come across
              any that we may have missed, please feel free to email us. As a
              token of appreciation for assisting our team, you will receive an
              award. However, in the event of bug abuse, we reserve the right to
              ban users from accessing the site.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography>Do I need to pass KYC to use this site?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Currently, we do not mandate KYC verification for our users.
              However, if our user base expands to a level where monitoring all
              user trades becomes challenging, we may implement limits and
              scopes in the deposit and withdrawal section. In cases where staff
              members suspect fraudulent or suspicious actions, some users may
              still be required to undergo KYC. All KYC information remains
              confidential. Any changes in rules or regulations will be
              communicated to users promptly.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <Typography>What is Api Scam?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The API scam method is a cunning and effective manipulation, as it
              is both easily encountered and challenging to detect. When
              attempting a trade, if the scammer's bot account gains access to
              your API KEY, it can exploit the opportunity to cancel the offer
              you sent. Subsequently, it can automatically alter the account's
              Steam profile picture and name to mimic the genuine recipient's
              profile. The scammer then sends a counter-offer for the initially
              selected item, all while the original offer is being made.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header"
          >
            <Typography>
              From where did your account attach the API bot?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The most prevalent method for inadvertently exposing your API key
              to scammers is by logging into fraudulent and imitation websites
              posing as popular services. These deceptive sites request your
              Steam credentials during the login process, allowing their bots to
              record your API information for later misuse. This typically
              occurs when you send an offer to someone on Steam. To avoid
              falling victim to scams, exercise caution when creating and
              accepting offers. Before sending an offer, carefully scrutinize
              the receiver's level and name. Has the name changed recently?
              After assessing this, send the offer and proceed to confirm it in
              your Steam Guard. Open the offer information and check if the
              receiver's level matches the original. You can also identify a bot
              if the original offer lacked the quote: 'This user has recently
              changed their name.' If the levels align, you can confidently
              proceed with the offer. However, if they differ, promptly go to
              your API Key settings and revoke it. Refresh the page; if no key
              remains, the impostors should no longer have access. For added
              safety, consider these steps: Change your Steam password (Note:
              Your trades will be temporarily blocked after the change).
              Generate a new Trade URL.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
          >
            <Typography>What to do if you already got scammed? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Unfortunately, once you have fallen victim to an API scam,
              retrieving your scammed items is not possible. Please do not worry
              about the lost items and instead focus on securing your account.
              Take preventive measures to avoid such incidents in the future by
              adhering to the information provided above. It would be better if
              you contact our support team, who will explain the security
              methods in more detail.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </CoverPaper>
  );
}
