import React, { useEffect, useState } from "react";
import ModalContent from "../ModalContent";
import { Modal, Box } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/store";
import { hideActiveOverlay } from "../../store/slices/modalSlice";
import { useLazyGetWithdrawDetailsQuery } from "../../api/profileApi";
import { withdrawStatus } from "../../Enums/withdrawStatusEnum";
import { IWithdrawDetails } from "../../types/ProfileTypes";

export const WITHDRAW_DETAILS_MODAL = "WITHDRAW_DETAILS_MODAL";

const WithdrawDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state: RootState) => state.modal);
  const [data, setData] = useState<IWithdrawDetails | undefined>();
  const handleClose = () => {
    dispatch(hideActiveOverlay());
  };
  const [fetchDetails] = useLazyGetWithdrawDetailsQuery();
  const fetchData = async () => {
    if (modalData.payload) {
      const response = await fetchDetails(modalData.payload.trade.temp_id);
      setData(response.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [modalData.payload]);

  return (
    <Modal
      open={modalData.activeOverlay === WITHDRAW_DETAILS_MODAL}
      onClose={handleClose}
    >
      <ModalContent>
        {modalData.payload && (
          <Box>
            <Box>price: {modalData.payload.trade.money}</Box>
            <Box>
              status: {withdrawStatus.get(modalData.payload.trade.status)}
            </Box>
            <Box>skins:</Box>
            <Box>
              {data &&
                data.skins.map((skin) => (
                  <Box key={skin.id}>
                    {skin.market_name} - {skin.price}
                  </Box>
                ))}
            </Box>
          </Box>
        )}
        <Box>OK</Box>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawDetailsModal;
