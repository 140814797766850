import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const CustomModal = styled(Box)<BoxProps>(({}) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  width: 600,
  boxShadow: 24,
  p: "20px 32px 40px",
  fontFamily: "Segoe UI",
}));

export default CustomModal;
