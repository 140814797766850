import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { IInventory } from "../../types/withdrawTypes";
import { memo } from "react";

interface Iprops {
  item: IInventory;
  checked: boolean;
  setChecked: (checked: boolean, asset: IInventory) => () => void;
}

const MarketplaceCard: React.FC<Iprops> = ({ item, checked, setChecked }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      sx={{
        width: {
          xs: "40%",
          md: "18%",
          lg: "13%",
          userSelect: "none",
          MozUserFocus: "none",
          WebkitUserSelect: "none",
        },
        border: "1px solid",
        borderColor: checked ? "#90EE90" : "black",
        borderRadius: "20px",
        gap: "3px",
        cursor: item.fields.oos ? "not-allowed" : "pointer",
      }}
      textAlign="center"
      onClick={setChecked(checked, item)}
    >
      <Checkbox sx={{ position: "absolute", left: 0 }} checked={checked} />
      <Box
        component="img"
        src={`https://community.akamai.steamstatic.com/economy/image/${item.fields.picture_url}`}
        sx={{
          width: {
            xs: "50px",
            sm: "100px",
            md: "150px",
          },
        }}
        height="100%"
      />
      <Typography>{item.fields.market_name}</Typography>
      <Box>
        {" "}
        <Typography>
          {item.fields.oos ? "Out of stock" : `${item.fields.price} coins`}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(MarketplaceCard);
