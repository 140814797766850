import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const HomeLowSection = () => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      gap={{ md: 30, xs: 4 }}
      justifyContent="center"
      flexWrap={"wrap"}
    >
      <Tooltip title="The gamified functions of CS2Aces are entirely based on randomness and are not controlled by any external influences.">
        <StyledBox>
          <Box
            component="img"
            src="/assets/homegroups/pins.png"
            width="150px"
          />
          <Typography>Fair Gameplay</Typography>
        </StyledBox>
      </Tooltip>
      <Tooltip title="All trades and transactions with CS2Aces are confidential and fully secured from third-party interference.">
        <StyledBox>
          <Box
            component="img"
            src="/assets/homegroups/pchip.png"
            width="150px"
          />
          <Typography>Safe transactions</Typography>
        </StyledBox>
      </Tooltip>
      <Tooltip title="We offer one of the lowest fees for your transactions and do not keep any of them hidden">
        <StyledBox>
          <Box
            component="img"
            src="/assets/homegroups/chips.png"
            width="150px"
          />
          <Typography>Low fees</Typography>
        </StyledBox>
      </Tooltip>
    </Box>
  );
};

export default HomeLowSection;
