import baseApi from "./baseApi";
import { ITableResponse } from "../types/tableTypes";
import { ActionEnum } from "../Enums/actionEnum";

export const tableApi = baseApi.enhanceEndpoints({addTagTypes: ['table']}).injectEndpoints({
    endpoints: builder => ({
        getTables: builder.query<ITableResponse, void | {}>({
            query: () => 'livetables/',
            providesTags: ['table']
        }),
        decision: builder.mutation<string, { action: ActionEnum, tableId: string, value?: number }>({
           query: ({action, tableId, value}) => ({
            url: 'livetables/decision/',
            method: 'POST',
            body: {action, tableId, value}
           })
        }),
        join: builder.mutation<string, {tableId: string, balance: number}>({
            query: ({tableId, balance}) => ({
                url: `livetables/jointable/${tableId}`,
                method: 'POST',
                body: {balance}
            })
        })
    })
})

export const { useGetTablesQuery, useDecisionMutation, useJoinMutation } = tableApi;
