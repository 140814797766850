import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InvertedButton from "../InvertedButton";
import HomeLowSection from "./HomeLowSection";

const HomeHero: React.FC = () => {
  return (
    <Box
      width="100%"
      pt={{ md: "60px" }}
      px={{ lg: "60px", xl: "40px" }}
      display={"flex"}
      justifyContent={{ md: "space-between", xs: "center" }}
      flexWrap={"wrap"}
      alignItems={{ md: "center" }}
      sx={{
        userSelect: "none",
        msUserSelect: "none",
        WebkitUserSelect: "none",
        ".MuiTypography-root": {
          fontFamily: "Butch",
        },
        ".MuiButton-text": {
          fontFamily: "Butch",
        },
      }}
    >
      <Box
        width={{ xs: "80%", md: "47%" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={{ md: 8, xs: 3 }}
        pt={{ md: 0, xs: 5 }}
      >
        <Typography variant="h3" color="#36993b">
          Certified Skin Marketplace.
        </Typography>
        <Typography variant="h4">
          Start Trading as a Jack, Meanwhile Become a King.
        </Typography>
        <InvertedButton
          bgColor="success.main"
          actColor="#fff"
          sx={{ fontSize: 30 }}
          href="/beta"
        >
          Continue With steam
        </InvertedButton>
      </Box>
      <Box width={{ md: "45%" }} display={{ xs: "none", md: "block" }}>
        <Box component="img" src="/assets/homeHero.png" width="100%" />
      </Box>
      <HomeLowSection />
    </Box>
  );
};

export default HomeHero;
