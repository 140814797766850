import { useState, useCallback } from "react";
import Container from "@mui/material/Container";
import CoverPaper from "../../components/CoverPaper";
import { useGetStockQuery } from "../../api/profileApi";
import Box from "@mui/material/Box";
import Loading from "../../components/Loading";
import { IInventory } from "../../types/withdrawTypes";
import MarketplaceCard from "./MarketplaceCard";
import MarketplaceLowOverlay from "./MarketplaceLowOverlay";
const Marketplace: React.FC = () => {
  const { data: stockItems, isLoading } = useGetStockQuery();
  const [checkData, setCheckData] = useState<Array<IInventory>>([]);

  const onChangeCallBack = useCallback(
    (checked: boolean, asset: IInventory) => () => {
      if (asset.fields.oos) {
        return;
      }
      if (checked) {
        setCheckData((prev) => prev.filter((check) => check.pk !== asset.pk));
      } else {
        setCheckData((prev) => [...prev, asset]);
      }
    },
    []
  );

  const afterSubmitCallback = useCallback(() => {
    setCheckData([]);
  }, []);
  return (
    <CoverPaper>
      <Container maxWidth="xl">
        {isLoading || !stockItems ? (
          <Loading />
        ) : (
          <Box display="flex" gap={{ xs: 3, md: 20 }} flexWrap="wrap">
            {stockItems.map((item) => (
              <MarketplaceCard
                key={item.pk}
                checked={checkData.includes(item)}
                setChecked={onChangeCallBack}
                item={item}
              />
            ))}
          </Box>
        )}
      </Container>
      {checkData.length > 0 && (
        <MarketplaceLowOverlay
          items={checkData}
          afterSubmit={afterSubmitCallback}
        />
      )}
    </CoverPaper>
  );
};

export default Marketplace;
