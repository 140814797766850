import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetTradeHistoryQuery,
} from "../../../api/profileApi";

const TradeHistory: React.FC = () => {
  const { data, isLoading } = useGetTradeHistoryQuery();

  return (
    <TableContainer component={Paper} sx={{ minWidth: 320, maxWidth: 800 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Created At</Typography>
            </TableCell>
            <TableCell>
              <Typography>Trade Offer Id</Typography>
            </TableCell>
            <TableCell>
              <Typography>Balance</Typography>
            </TableCell>
            <TableCell>
              <Typography>Status</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(isLoading || !data) && (
            <TableRow>
              <TableCell>Loading...</TableCell>
            </TableRow>
          )}
          {data &&
            data.trades.map((trade) => (
              <TableRow key={trade.tradeOfferId}>
                <TableCell>
                  <Typography>
                    {new Date(trade.created_at).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{trade.tradeOfferId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{trade.money || "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{trade.status}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TradeHistory;
