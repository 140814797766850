import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const CountdownTimer: React.FC<{ serverTimestamp: number }> = ({
  serverTimestamp,
}) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const countdownDuration = 14 * 1000; // 15 seconds in milliseconds

    const updateProgress = () => {
      const elapsedTime = Date.now() - serverTimestamp;
      const remainingTime = countdownDuration - elapsedTime;

      if (remainingTime <= 0) {
        setProgress(0);
      } else {
        setProgress((remainingTime / countdownDuration) * 100);
      }
    };

    // Initial update
    updateProgress();

    const interval = setInterval(updateProgress, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [serverTimestamp]);

  return (
    <CircularProgress
      variant="determinate"
      value={progress}
      size={130}
      thickness={5}
      sx={{ position: "absolute", zIndex: 1 }}
      color="success"
    />
  );
};

export default CountdownTimer;
