import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Iprofile } from '../../types/ProfileTypes'

interface userStoreState {
    user: Iprofile | undefined
}

const initialState: userStoreState = {
    user: undefined
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        authenticate: (state, action: PayloadAction<Iprofile>) => {
            state.user = action.payload
        },
        logout: (state) => {
            state.user = undefined
        },
        updateTradeOffer: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user.tradeOfferUrl = action.payload
            }
        },
        updateBalance: (state, action: PayloadAction<number>) => {
            if (state.user) {
                state.user.balance = action.payload
            }
        },
    }
})

export const { authenticate, logout, updateTradeOffer, updateBalance } = userSlice.actions

export default userSlice.reducer